import React, { useEffect, useState } from 'react'

import '../../css/components/AdminComponents/CategoriesModal.css'

const CategoriesModal = ({ associatedCategories, allCategories, onClose, onConfirm }) => {
    
    const [leftCategories, setLeftCategories] = useState([]);
    const [rightCategories, setRightCategories] = useState([]);
    const [isLeftDragOver, setIsLeftDragOver] = useState(false);
    const [isRightDragOver, setIsRightDragOver] = useState(false);

    useEffect(() => {
        setLeftCategories(associatedCategories || []);
        const associatedIds = (associatedCategories || []).map(f => f.id);
        const unassociatedCategories = allCategories.filter(f => !associatedIds.includes(f.id));
        setRightCategories(unassociatedCategories);
    }, [associatedCategories, allCategories]);

    const handleDragStart = (e, category, fromLeft) => {
        e.dataTransfer.setData('category', JSON.stringify(category));
        e.dataTransfer.setData('fromLeft', fromLeft);
    };

    const handleDrop = (e, targetLeft) => {
        e.preventDefault();
        const category = JSON.parse(e.dataTransfer.getData('category'));
        const fromLeft = e.dataTransfer.getData('fromLeft') === 'true';

        if (fromLeft && !targetLeft) {
            setLeftCategories(leftCategories.filter(f => f.id !== category.id));
            setRightCategories([...rightCategories, category]);
        } else if (!fromLeft && targetLeft) {
            setRightCategories(rightCategories.filter(f => f.id !== category.id));
            setLeftCategories([...leftCategories, { ...category, stock: 0 }]); // Ajoutez un champ stock par défaut
        }

        if (targetLeft) {
            setIsLeftDragOver(false);
        } else {
            setIsRightDragOver(false);
        }
    };

    const allowDrop = (e) => {
        e.preventDefault();
    };

    const handleDragEnter = (e, targetLeft) => {
        e.preventDefault();
        if (targetLeft) {
            setIsLeftDragOver(true);
        } else {
            setIsRightDragOver(true);
        }
    };

    const handleDragLeave = (e, targetLeft) => {
        e.preventDefault();
        if (targetLeft) {
            setIsLeftDragOver(false);
        } else {
            setIsRightDragOver(false);
        }
    };

    const handleConfirm = () => {
        onConfirm(leftCategories);
        onClose();
    };
    
    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h2>Gérer les entrées de la page "Infos"</h2>
                <div className="modal-body">
                    <div
                        className={`category-list left ${isLeftDragOver ? 'drag-over' : ''}`}
                        onDrop={(e) => handleDrop(e, true)}
                        onDragOver={allowDrop}
                        onDragEnter={(e) => handleDragEnter(e, true)}
                        onDragLeave={(e) => handleDragLeave(e, true)}
                    >
                        <h3>Entrées associés</h3>
                        <div className="categories-container">
                            {leftCategories.map((category) => (
                                <div
                                    key={category.id}
                                    draggable
                                    onDragStart={(e) => handleDragStart(e, category, true)}
                                    className="category-item"
                                >
                                    <span>{category.name}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div
                        className={`category-list right ${isRightDragOver ? 'drag-over' : ''}`}
                        onDrop={(e) => handleDrop(e, false)}
                        onDragOver={allowDrop}
                        onDragEnter={(e) => handleDragEnter(e, false)}
                        onDragLeave={(e) => handleDragLeave(e, false)}
                    >
                        <h3>Entrées disponibles</h3>
                        <div className="categories-container">
                            {rightCategories.map((category) => (
                                <div
                                    key={category.id}
                                    draggable
                                    onDragStart={(e) => handleDragStart(e, category, false)}
                                    className="category-item"
                                >
                                    <span>{category.name}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <button onClick={handleConfirm}>Valider</button>
                    <button onClick={onClose}>Annuler</button>
                </div>
            </div>
        </div>
    )
}

export default CategoriesModal