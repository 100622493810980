import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import { ShopHeader } from '../components/HeaderComponents/ShopHeader';
import Image from '../components/HTMLComponents/Image';
import InfosSection from '../components/InfosSection';

import '../css/pages/Infos.css';

const Infos = () => {
    const [infos, setInfos] = useState([]); // Tableau d'informations
    const [loading, setLoading] = useState(true); // État de chargement
    const [error, setError] = useState(null); // Gestion des erreurs
    const [selectedFilters, setSelectedFilters] = useState({
        checkboxes: [] // Gère uniquement les cases à cocher
    });

    let classname = 'infos';
    if (window.location.pathname === '/infos') classname += ' fixed_main';

    useEffect(() => {
        const fetchInfos = async () => {
            try {
                const apiUrl = process.env.REACT_APP_API_URL;
                const response = await fetch(`${apiUrl}/infos`);
                if (!response.ok) throw new Error("Erreur lors de la récupération de l'information");
                const data = await response.json();
                setInfos(data);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchInfos();
    }, []);

    const handleFilterChange = (dataValue, isChecked) => {
        setSelectedFilters((prev) => ({
            ...prev,
            checkboxes: isChecked
                ? [...prev.checkboxes, dataValue] // Ajouter au filtre sélectionné
                : prev.checkboxes.filter((value) => value !== dataValue), // Retirer du filtre sélectionné
        }));
    };

    const filteredInfos = infos.map((info) => {
        // Filtrer les catégories
        const filteredCategories = info.categories.map((category) => {
            // Vérifier si le nom de la catégorie correspond aux filtres
            const matchesCategoryName =
                selectedFilters.checkboxes.length === 0 || // Aucun filtre catégorie
                selectedFilters.checkboxes.includes(category.name); // Catégorie correspond à un filtre

            // Filtrer les entrées par année si applicable
            const filteredEntries = category.entries.filter((entry) =>
                selectedFilters.checkboxes.length === 0 || // Aucun filtre année
                selectedFilters.checkboxes.includes(entry.year) // L'année correspond à un filtre
            );

            // Si on filtre par catégorie, inclure toutes les entrées correspondantes
            if (matchesCategoryName) {
                return { ...category, entries: filteredEntries.length > 0 ? filteredEntries : category.entries };
            }

            // Sinon, ne conserver que les catégories avec des entrées valides
            return filteredEntries.length > 0 ? { ...category, entries: filteredEntries } : null;
        }).filter((category) => category !== null); // Retirer les catégories vides

        return { ...info, categories: filteredCategories };
    }).filter((info) => info.categories.length > 0); // Retirer les infos sans catégories valides

    if (loading) return <div>Chargement des informations...</div>;
    if (error) return <div>Erreur : {error}</div>;
    if (infos.length === 0) return <div>Aucune information disponible.</div>;

    return (
        <>
            <Header
                nameFilter={"infos"} 
                filters={infos}
                onFilterChange={handleFilterChange}
                selectedFilters={selectedFilters}
            />
            <main className={classname}>
                {filteredInfos.map((info) => (
                    <div key={info.id} className="info_section">
                        <section className="infos-owner">
                            {info.picture && info.picture.length > 0 && (
                                <Image
                                    id={info.picture[0].id}
                                    path={info.picture[0].url}
                                    alt={info.picture[0].altText}
                                    title={info.picture[0].title}
                                    classname={"infos-owner-image"}
                                />
                            )}
                            <h1>{info.name}</h1>
                            <p>{info.description}</p>
                        </section>
                        {info.categories.map((category) => (
                            <InfosSection
                                key={category.id}
                                title={category.name}
                                datas={category.entries}
                            />
                        ))}
                    </div>
                ))}
            </main>
            <ShopHeader />
        </>
    );
};

export default Infos;
