import React, { useEffect, useState } from 'react'

import '../../css/components/AdminComponents/EntriesModal.css'

const EntriesModal = ({ associatedEntries, allEntries, onClose, onConfirm }) => {
    
    const [leftEntries, setLeftEntries] = useState([]);
    const [rightEntries, setRightEntries] = useState([]);
    const [isLeftDragOver, setIsLeftDragOver] = useState(false);
    const [isRightDragOver, setIsRightDragOver] = useState(false);

    useEffect(() => {
        setLeftEntries(associatedEntries || []);
        const associatedIds = (associatedEntries || []).map(f => f.id);
        const unassociatedEntries = allEntries.filter(f => !associatedIds.includes(f.id));
        setRightEntries(unassociatedEntries);
    }, [associatedEntries, allEntries]);

    const handleDragStart = (e, entry, fromLeft) => {
        e.dataTransfer.setData('entry', JSON.stringify(entry));
        e.dataTransfer.setData('fromLeft', fromLeft);
    };

    const handleDrop = (e, targetLeft) => {
        e.preventDefault();
        const entry = JSON.parse(e.dataTransfer.getData('entry'));
        const fromLeft = e.dataTransfer.getData('fromLeft') === 'true';

        if (fromLeft && !targetLeft) {
            setLeftEntries(leftEntries.filter(f => f.id !== entry.id));
            setRightEntries([...rightEntries, entry]);
        } else if (!fromLeft && targetLeft) {
            setRightEntries(rightEntries.filter(f => f.id !== entry.id));
            setLeftEntries([...leftEntries, { ...entry, stock: 0 }]); // Ajoutez un champ stock par défaut
        }

        if (targetLeft) {
            setIsLeftDragOver(false);
        } else {
            setIsRightDragOver(false);
        }
    };

    const allowDrop = (e) => {
        e.preventDefault();
    };

    const handleDragEnter = (e, targetLeft) => {
        e.preventDefault();
        if (targetLeft) {
            setIsLeftDragOver(true);
        } else {
            setIsRightDragOver(true);
        }
    };

    const handleDragLeave = (e, targetLeft) => {
        e.preventDefault();
        if (targetLeft) {
            setIsLeftDragOver(false);
        } else {
            setIsRightDragOver(false);
        }
    };

    const handleConfirm = () => {
        onConfirm(leftEntries);
        onClose();
    };
    
    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h2>Gérer les entrées de la page "Infos"</h2>
                <div className="modal-body">
                    <div
                        className={`entry-list left ${isLeftDragOver ? 'drag-over' : ''}`}
                        onDrop={(e) => handleDrop(e, true)}
                        onDragOver={allowDrop}
                        onDragEnter={(e) => handleDragEnter(e, true)}
                        onDragLeave={(e) => handleDragLeave(e, true)}
                    >
                        <h3>Entrées associés</h3>
                        <div className="entries-container">
                            {leftEntries.map((entry) => (
                                <div
                                    key={entry.id}
                                    draggable
                                    onDragStart={(e) => handleDragStart(e, entry, true)}
                                    className="entry-item"
                                >
                                    <span>{entry.text}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div
                        className={`entry-list right ${isRightDragOver ? 'drag-over' : ''}`}
                        onDrop={(e) => handleDrop(e, false)}
                        onDragOver={allowDrop}
                        onDragEnter={(e) => handleDragEnter(e, false)}
                        onDragLeave={(e) => handleDragLeave(e, false)}
                    >
                        <h3>Entrées disponibles</h3>
                        <div className="entries-container">
                            {rightEntries.map((entry) => (
                                <div
                                    key={entry.id}
                                    draggable
                                    onDragStart={(e) => handleDragStart(e, entry, false)}
                                    className="entry-item"
                                >
                                    <span>{entry.text}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <button onClick={handleConfirm}>Valider</button>
                    <button onClick={onClose}>Annuler</button>
                </div>
            </div>
        </div>
    )
}

export default EntriesModal