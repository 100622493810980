import React from 'react'
import { NavLink, useNavigate } from 'react-router-dom'

const Dashboard = () => {
    const navigate = useNavigate();

    const handleLogout = async () => {
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const response = await fetch(`${apiUrl}/logout`, {
                method: 'POST',
                credentials: 'include', // Assure que les cookies sont inclus
            });

            const data = await response.json();
            if (data.success) {
                // Redirige l'utilisateur vers la page de connexion après la déconnexion
                navigate('/admin');
            } else {
                console.error('Erreur lors de la déconnexion');
            }
        } catch (error) {
            console.error('Erreur réseau lors de la déconnexion', error);
        }
    };
    
    return (
        <section className='dashbord'>
            <h1>Section administrateur</h1>
            <ul>
                <li><NavLink to="/admin/users">Paramétrer les identifiants</NavLink></li>
                <li><NavLink to="/admin/keywords">Gérer les mots-clés</NavLink></li>
                <li><NavLink to="/admin/pictures">Gérer les images</NavLink></li>
                <li><NavLink to="/admin/projects">Gérer les projets</NavLink></li>
                <li><NavLink to="/admin/activities">Gérer les activités</NavLink></li>
                <li><NavLink to="/admin/collections">Gérer les collections</NavLink></li>
                <li><NavLink to="/admin/series">Gérer les séries</NavLink></li>
                <li><NavLink to="/admin/formats">Gérer les formats</NavLink></li>
                <li><NavLink to="/admin/products">Gérer les produits</NavLink></li>
                <li><NavLink to="/admin/payments">Gérer les moyens de paiements</NavLink></li>
                <li><NavLink to="/admin/shipments">Gérer les types d'envoi</NavLink></li>
                <li><NavLink to="/admin/infos">Gérer l'entête de la page Infos</NavLink></li>
                <li><NavLink to="/admin/categories">Gérer les catégories de la page Infos</NavLink></li>
                <li><NavLink to="/admin/entries">Gérer les entrées de la page Infos</NavLink></li>
                <li><NavLink to="/admin/recyclebin">Voir la corbeille</NavLink></li>
                <li><NavLink to="/admin" onClick={handleLogout}>Se déconnecter</NavLink></li>
            </ul>
        </section>
    )
}

export default Dashboard