import React, { useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom';

import EntriesModal from '../../components/AdminComponents/EntriesModal'

import "../../css/pages/admin/Categories.css"

const Categories = () => {

    const [categories, setCategories] = useState([]);
    const [name, setName] = useState('');
    const [entries, setEntries] = useState([]);
    const [allEntries, setAllEntries] = useState([]);
    const [showEntriesModal, setShowEntriesModal] = useState(false);
    const [editIndex, setEditIndex] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const apiUrl = process.env.REACT_APP_API_URL;
                const response = await fetch(`${apiUrl}/categories`);
                if (!response.ok) {
                    throw new Error('Erreur lors du chargement des différentes catégories de la page "Infos"');
                }
                const data = await response.json();
                setCategories(data);
                setLoading(false);
            } catch (err) {
                setError(err.message);
                setLoading(false);
            }
        };
        fetchCategories();
    }, []);

    useEffect(() => {
        const fetchEntries = async () => {
            try {
                const apiUrl = process.env.REACT_APP_API_URL;
                const response = await fetch(`${apiUrl}/entries`);
                if (!response.ok) {
                    throw new Error('Erreur lors du chargement des entrées de la page "Infos"');
                }
                const data = await response.json();
                setAllEntries(data);
            } catch (err) {
                setError(err.message);
            }
        };
        fetchEntries();
    }, []);

    const handleAddCategorie = async (newCategorie) => {
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const response = await fetch(`${apiUrl}/categories/add`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(newCategorie),
            });

            if (!response.ok) {
                throw new Error('Erreur lors de l\'ajout de la nouvelle catégorie pour la page "Infos"');
            }

            const createdCategorieId = (await response.json()).categorieId;
            const createdCategorie = { id: createdCategorieId, ...newCategorie };
            setCategories([...categories, createdCategorie]);
        } catch (err) {
            setError(err.message);
        }
    };

    const handleUpdateCategorie = async (index, updatedCategorie) => {
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const categorieId = categories[index].id;
            const response = await fetch(`${apiUrl}/categories/${categorieId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(updatedCategorie),
            });

            if (!response.ok) {
                throw new Error('Erreur lors de la mise à jour de la catégorie de la page "Infos"');
            }

            const updatedCategories = [...categories];
            updatedCategories[index] = { ...categories[index], ...updatedCategorie };
            setCategories(updatedCategories);
            setEditIndex(null);
        } catch (err) {
            setError(err.message);
        }
    };

    const handleDeleteCategorie = async (index) => {
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const categorieId = categories[index].id;
            const response = await fetch(`${apiUrl}/categories/${categorieId}/delete`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Erreur lors de la suppression de l\'ancienne catégorie de la page "Infos"');
            }

            const updatedCategories = categories.filter((_, i) => i !== index);
            setCategories(updatedCategories);
        } catch (err) {
            setError(err.message);
        }
    };

    const refreshCategories = async () => {
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const response = await fetch(`${apiUrl}/categories`);
            if (!response.ok) throw new Error('Erreur lors du rechargement des catégories de la page "Infos"');
            const data = await response.json();
            setCategories(data);
        } catch (err) {
            console.error('Erreur lors du rechargement de la catégorie de la page "Infos" :', err);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const newCategorie = {
            name,
            entries: entries.map(entry => ({
                id_entry: entry.id
            }))
        };

        if (editIndex !== null) {
            await handleUpdateCategorie(editIndex, newCategorie);
        } else {
            await handleAddCategorie(newCategorie);
        }

        await refreshCategories();

        setName('');
        setEntries([]);
        setEditIndex(null);
    };

    const handleEdit = (index) => {
        if (editIndex !== null && index === editIndex) {
            setEditIndex(null);
            setName('');
            setEntries([]);
        } else {
            const categorie = categories[index];
            setName(categorie.name || '');
            setEntries(categorie.entries || []);
            setEditIndex(index);
        }
    };

    const openEntriesModal = () => {
        setShowEntriesModal(true);
    };

    const closeEntriesModal = () => {
        setShowEntriesModal(false);
    };

    const handleEntriesChange = (selectedEntries) => {
        setEntries(selectedEntries);
    };

    if (loading) {
        return <div>Chargement des catégories de la page "Infos"...</div>;
    }

    if (error) {
        return <div>Erreur : {error}</div>;
    }

    return (
        <section className="admin_categories">
            <div>
                <h1>Gestion des catégories de la page "Infos"</h1>
                <form onSubmit={handleSubmit}>
                    <div>
                        <label htmlFor="name">Nom: </label>
                        <input
                            type="text"
                            id="name"
                            value={name || ''}
                            onChange={(e) => setName(e.target.value)}
                            required
                        />
                    </div>
                    <div>
                        <button type="button" onClick={openEntriesModal}>Gérer les entrées</button>
                        <span>{entries.length} entrée(s) sélectionnée(s)</span>
                    </div>
                    <button type="submit">{editIndex !== null ? 'Mettre à jour' : 'Ajouter'}</button>
                </form>
                <NavLink to="/admin/dashboard">Retour à l'accueil</NavLink>
            </div>
            <div className="vertical-line"></div>
            <div>
                <h2>Catégories de la page "Infos"</h2>
                <ul>
                    {categories.map((categorie, index) => (
                        <li key={categorie.id}>
                            <div
                                className="adminCategorieDiv"
                                onClick={() => handleEdit(index)}
                            >
                                <span>{categorie.name}</span>
                            </div>
                            <button onClick={() => handleDeleteCategorie(index)}>Supprimer</button>
                        </li>
                    ))}
                </ul>
            </div>
            {showEntriesModal && (
                <EntriesModal
                    associatedEntries={entries}
                    allEntries={allEntries}
                    onClose={closeEntriesModal}
                    onConfirm={handleEntriesChange}
                />
            )}
        </section>
    )
}

export default Categories