import React, { useState, useEffect } from 'react';

import Header from '../components/Header';
import { ShopHeader } from '../components/HeaderComponents/ShopHeader';
import { CartModalShop } from '../components/ShopComponents/CartModalShop';
import { ShopImage } from '../components/ShopComponents/ShopImage';

import '../css/pages/Shop.css';

/**
 * Composant qui affiche la page "Shop" du site web
 * @returns {JSX.Element}
 */
export const Shop = () => {
  const [isModalCartDisplay, setIsModalCartDisplay] = useState(false);
  const [items, setItems] = useState([]);
  const [collections, setCollections] = useState([]);
  const [error, setError] = useState(null);
  const [selectedFilters, setSelectedFilters] = useState({
    checkboxes: [], // Pour les filtres de type checkbox
    maxPrice: null, // Pour le filtre de prix
  });
  const [loading, setLoading] = useState(true);

  let classname = 'shop';

  if (window.location.pathname === '/boutique')
    classname += ' fixed_main';

  useEffect(() => {
    const fetchCollections = async () => {
      try {
        const apiUrl = process.env.REACT_APP_API_URL;
        const response = await fetch(`${apiUrl}/collections/details`);
        if (!response.ok) {
          throw new Error('Erreur lors du chargement des collections');
        }
        const data = await response.json();
  
        // Calcul du prix maximum
        const allPrices = data.flatMap(collection =>
          collection.series?.flatMap(serie =>
            serie.products?.map(product => product.price_ttc) || []
          ) || []
        );
        const calculatedMaxPrice = allPrices.length > 0 ? Math.max(...allPrices) : 1000;
  
        // Mettre à jour les collections et les filtres
        setCollections(data);
        setSelectedFilters((prev) => ({
          ...prev,
          maxPrice: calculatedMaxPrice,
        }));
      } catch (err) {
        console.error(err.message);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    fetchCollections();
  }, []);

  function displayModal() {
    const bodyElement = document.getElementsByTagName("body");
    setIsModalCartDisplay(prev => !prev);
    if (!isModalCartDisplay)
      bodyElement[0].classList.add('stop-overflow');
    else
      bodyElement[0].classList.remove('stop-overflow');
  }

  function handleModalCart(product, format) {
    if (!product) {
      alert('Produit introuvable.');
      return;
    }

    const selectedFormat = product.formats.find((f) => f.name === format);
    const stock = selectedFormat ? selectedFormat.stock : 0;

    if (stock === 0) {
      alert('Ce produit est en rupture de stock.');
      return;
    }

    let cart = [];
    if (window.localStorage.getItem('cart')) {
      cart = JSON.parse(window.localStorage.getItem('cart'));
    }

    const cartItemIndex = cart.findIndex(
      (item) => item.id === product.id && item.format === format
    );

    if (cartItemIndex > -1) {
      if (cart[cartItemIndex].qty + 1 > stock) {
        alert(`Stock insuffisant pour ajouter ce produit. Stock disponible : ${stock}.`);
        return;
      }
      cart[cartItemIndex].qty += 1;
    } else {
      cart.push({
        id: product.id,
        format: format,
        qty: 1,
        name: product.name,
        price_ttc: product.price_ttc,
      });
    }

    window.localStorage.setItem('cart', JSON.stringify(cart));
    setItems(cart);
    displayModal();
  }

  const handleFilterChange = (key, value) => {
    setSelectedFilters((prev) => {
      if (key === 'maxPrice') {
        return {
          ...prev,
          maxPrice: value, // Met à jour le prix maximum, même s'il est à 0
        };
      }
  
      if (typeof value === 'boolean') {
        const updatedCheckboxes = value
          ? [...prev.checkboxes, key]
          : prev.checkboxes.filter((item) => item !== key);
        return {
          ...prev,
          checkboxes: updatedCheckboxes,
        };
      }
  
      return prev;
    });
  };

  const filteredCollections =
  collections.map((collection) => {
    const filteredSeries = collection.series.map((serie) => {
      const filteredProducts = serie.products.filter((product) => {
        const isBelowMaxPrice =
          selectedFilters.maxPrice == null || 
          (product.price_ttc != null && product.price_ttc <= selectedFilters.maxPrice);

        const matchesCheckboxes =
          selectedFilters.checkboxes.length === 0 ||
          selectedFilters.checkboxes.includes(collection.name) ||
          selectedFilters.checkboxes.includes(serie.title) ||
          selectedFilters.checkboxes.includes(product.name) ||
          product.formats.some((format) => selectedFilters.checkboxes.includes(format.name));

        return isBelowMaxPrice && matchesCheckboxes;
      });

      return { ...serie, products: filteredProducts };
    }).filter((serie) => serie.products.length > 0);

    return { ...collection, series: filteredSeries };
  }).filter((collection) => collection.series.length > 0);

  if (loading) return <div>Chargement des produits...</div>;
  if (error) return <div>Erreur : {error}</div>;
  if (collections.length === 0) return <div>Aucun produit disponible.</div>;

  return (
    <>
      <Header 
        nameFilter={"shop"} 
        filters={collections} 
        onFilterChange={handleFilterChange} 
        selectedFilters={selectedFilters}
      />
      <main className={classname}>
        {filteredCollections.map((collection) => (
          <div key={collection.id}>
            {collection.series.map((serie) => (
              <div key={serie.id}>
                <section className="shop_header">
                  <h1>Collection {collection.name}</h1>
                  <h2>Série {serie.title}</h2>
                </section>
                <section className='shop_grid'>
                  {serie.products.map((product) => (
                    <ShopImage
                      key={product.id}
                      id={product.id}
                      path={product.pictures && product.pictures.length > 0 ? product.pictures[0].url : 'default_image_url'}
                      alt={product.name}
                      title={product.name}
                      price={`${product.price_ttc}€`}
                      handleModal={handleModalCart}
                      product={product}
                    />
                  ))}
                </section>
              </div>
            ))}
          </div>
        ))}
        {isModalCartDisplay &&
          <CartModalShop onClickParent={displayModal} cartItems={items} setItems={setItems} />
        }
      </main>
      <ShopHeader />
    </>
  );
};
