export const filterConfig = {
  shop: [
    { label: 'COLLECTION', field: 'collection' },
    { label: 'SERIE', field: 'serie' },
    { label: 'PRODUIT', field: 'product' },
    { label: 'FORMAT', field: 'format' },
    { label: 'PRIX', field: 'price' }
  ],
  projects: [
    { label: 'TITRE', field: 'title' },
    { label: 'ANNEE', field: 'year' }
  ],
  activities: [
    { label: 'TITRE', field: 'title' },
    { label: 'ANNEE', field: 'year' }
  ],
  infos: [
    { label : 'CATEGORIE', field: 'name'},
    { label : 'ANNEE', field: 'entryYear'}
  ]
};
