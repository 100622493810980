import '../../../css/components/HeaderComponents/FilterComponents/FilterChecklist.css'

const FilterChecklist = ({ name, datas, onChange, selectedFilters, maxPrice }) => {
  if (name === 'PRIX') {
    const currentMaxPrice = selectedFilters?.maxPrice ?? maxPrice;

    return (
      <div className="filter_checklist">
        <h1>{name}</h1>
        <div className="price-filter">
          <label>Prix maximum : {currentMaxPrice}€</label>
          <input
            type="range"
            min="0"
            max={maxPrice}
            step="1"
            value={currentMaxPrice}
            onChange={(e) => onChange('maxPrice', parseInt(e.target.value, 10))}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="filter_checklist">
      <strong>
        <h1>{name}</h1>
      </strong>
      {datas.map((data, index) => {
        const displayValue = typeof data === 'object' ? JSON.stringify(data) : data;
        const isChecked = selectedFilters.checkboxes.includes(displayValue);

        return (
          <div key={`${name}-${index}`} className="filter_item">
            <label>
              <input
                type="checkbox"
                checked={isChecked}
                onChange={(e) => onChange(displayValue, e.target.checked)}
              />
              <span>{displayValue}</span>
            </label>
          </div>
        );
      })}
    </div>
  );
};

export default FilterChecklist;
