import React from 'react'
import "../css/components/InfosSection.css"

/**
 * Component which render a section for each infos about the owner of the website
 * @param {object} props
 * @param {string} props.title - Title of the section (nom de la catégorie)
 * @param {Object[]} props.datas - All the data (entries) displaying in each section
 * @param {number} props.datas.id
 * @param {string} props.datas.year
 * @param {string} props.datas.text
 * @param {string} [props.datas.url] - optional link
 * @returns {JSX.Element} Render the InfosSection component
 */
export default function InfosSection({ title, datas }) {
    return (
        <section className='infos-details'>
            <h1>{title}</h1>
            {datas.map(data => {
                const content = data.url ? (
                    <a href={data.url} target="_blank" rel="noopener noreferrer">
                        {data.year} - {data.text}
                    </a>
                ) : (
                    <span>{data.year} - {data.text}</span>
                );

                return <article key={data.id}>{content}</article>;
            })}
        </section>
    )
}
