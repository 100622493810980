import React from 'react';

import { LogoHeader } from './HeaderComponents/LogoHeader.jsx';
import { NavHeader } from './HeaderComponents/NavHeader.jsx';
import { FilterHeader } from './HeaderComponents/FilterHeader.jsx';
import { SocialsHeader } from './HeaderComponents/SocialsHeader.jsx';
import { Footer } from './Footer.jsx';

import '../css/components/Header.css';

export default function Header({ nameFilter, filters, onFilterChange, selectedFilters }) {
  let classname = null;

  if (['/projets', '/activites', '/boutique', '/infos'].includes(window.location.pathname))
    classname = 'fixed';
  else
    classname = 'without-filter';

  if (window.location.pathname === '/panier')
    classname = 'header-cart';

  return (
    <header className={classname}>
      <LogoHeader />
      <NavHeader />
      {nameFilter && filters && onFilterChange && selectedFilters ? (
        <FilterHeader
          nameFilter={nameFilter}
          filters={filters}
          onFilterChange={onFilterChange}
          selectedFilters={selectedFilters}
        />
      ) : null}
      <SocialsHeader />
      <Footer />
    </header>
  );
}
