import React, { useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom';

import PicturesModal from '../../components/AdminComponents/PicturesModal';
import CategoriesModal from '../../components/AdminComponents/CategoriesModal'

import '../../css/pages/admin/Infos.css';
import Image from '../../components/HTMLComponents/Image';

const Infos = () => {
    const [infos, setInfos] = useState([]);
    const [pictures, setPictures] = useState([]);
    const [categories, setCategories] = useState([]);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [allPictures, setAllPictures] = useState([]);
    const [allCategories, setAllCategories] = useState([]);
    const [showPicturesModal, setShowPicturesModal] = useState(false);
    const [showCategoriesModal, setShowCategoriesModal] = useState(false);
    const [editIndex, setEditIndex] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchInfos = async () => {
            try {
                const apiUrl = process.env.REACT_APP_API_URL;
                const response = await fetch(`${apiUrl}/infos`);
                if (!response.ok) {
                    throw new Error('Erreur lors du chargement de l\'information');
                }
                const data = await response.json();
                setInfos(data);
                setLoading(false);
            } catch (err) {
                setError(err.message);
                setLoading(false);
            }
        };
        fetchInfos();
    }, []);

    useEffect(() => {
        const fetchPictures = async () => {
            try {
                const apiUrl = process.env.REACT_APP_API_URL;
                const response = await fetch(`${apiUrl}/pictures`);
                if (!response.ok) {
                    throw new Error('Erreur lors du chargement des images');
                }
                const data = await response.json();
                setAllPictures(data);
            } catch (err) {
                setError(err.message);
            }
        };
        fetchPictures();
    }, []);

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const apiUrl = process.env.REACT_APP_API_URL;
                const response = await fetch(`${apiUrl}/categories`);
                if (!response.ok) {
                    throw new Error('Erreur lors du chargement des entrées de la page "Infos"');
                }
                const data = await response.json();
                setAllCategories(data);
            } catch (err) {
                setError(err.message);
            }
        };
        fetchCategories();
    }, []);

    const handleAddInfo = async (newInfo) => {
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const response = await fetch(`${apiUrl}/infos/add`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(newInfo),
            });

            if (!response.ok) {
                throw new Error('Erreur lors de l\'ajout de la nouvelle information');
            }

            const createdInfoId = (await response.json()).infoId;
            const createdInfo = { id: createdInfoId, ...newInfo };
            setInfos([...infos, createdInfo]);
        } catch (err) {
            setError(err.message);
        }
    };

    const handleUpdateInfo = async (index, updatedInfo) => {
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const infoId = infos[index].id;
            const response = await fetch(`${apiUrl}/infos/${infoId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(updatedInfo),
            });

            if (!response.ok) {
                throw new Error('Erreur lors de la mise à jour de la nouvelle information');
            }

            const updatedInfos = [...infos];
            updatedInfos[index] = { ...infos[index], ...updatedInfo };
            setInfos(updatedInfos);
            setEditIndex(null);
        } catch (err) {
            setError(err.message);
        }
    };

    const handleDeleteInfo = async (index) => {
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const infoId = infos[index].id;
            const response = await fetch(`${apiUrl}/infos/${infoId}/delete`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Erreur lors de la suppression de l\'ancienne information');
            }

            const updatedInfos = infos.filter((_, i) => i !== index);
            setInfos(updatedInfos);
        } catch (err) {
            setError(err.message);
        }
    };

    const refreshInfos = async () => {
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const response = await fetch(`${apiUrl}/infos`);
            if (!response.ok) throw new Error('Erreur lors du rechargement de l\'entête de la page "Infos"');
            const data = await response.json();
            setInfos(data);
        } catch (err) {
            console.error('Erreur lors du rechargement de l\'entête de la page "Infos" :', err);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const newInfo = {
            id_picture: pictures[0].id,
            name,
            description,
            categories: categories.map(categorie => ({
                id: categorie.id
            }))
        };

        if (editIndex !== null) {
            await handleUpdateInfo(editIndex, newInfo);
        } else {
            await handleAddInfo(newInfo);
        }

        await refreshInfos();

        setPictures([]);
        setCategories([]);
        setName('');
        setDescription('');
        setEditIndex(null);
    };

    const handleEdit = (index) => {
        if (editIndex !== null && index === editIndex) {
            setPictures([]);
            setCategories([]);
            setName('');
            setDescription('');
            setEditIndex(null);
        } else {
            const info = infos[index];
            setPictures(info.picture || []);
            setCategories(info.categories || []);
            setName(info.name || '');
            setDescription(info.description || '');
            setEditIndex(index);
        }
    };

    const openPicturesModal = () => {
        setShowPicturesModal(true);
    };

    const closePicturesModal = () => {
        setShowPicturesModal(false);
    };

    const handlePicturesChange = (selectedPictures) => {
        setPictures(selectedPictures);
    };

    const openCategoriesModal = () => {
        setShowCategoriesModal(true);
    };

    const closeCategoriesModal = () => {
        setShowCategoriesModal(false);
    };

    const handleCategoriesChange = (selectedCategories) => {
        setCategories(selectedCategories);
    };
    
    if (loading) {
        return <div>Chargement de l'information...</div>;
    }

    if (error) {
        return <div>Erreur : {error}</div>;
    }

    return (
        <section className="admin_infos">
            <div>
                <h1>Gestion de l'entête de la page "Infos"</h1>
                <form onSubmit={handleSubmit}>
                    <div>
                        <button type="button" onClick={openPicturesModal}>Gérer l'image</button>
                        <span>{pictures.length} image sélectionnée</span>
                    </div>
                    <div>
                        <label htmlFor="name">Nom: </label>
                        <input
                            type="text"
                            id="name"
                            value={name || ''}
                            onChange={(e) => setName(e.target.value)}
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="description">Description: </label>
                        <textarea
                            type="text"
                            id="description"
                            value={description || ''}
                            onChange={(e) => setDescription(e.target.value)}
                            required
                        />
                    </div>
                    <div>
                        <button type="button" onClick={openCategoriesModal}>Gérer les catégories</button>
                        <span>{categories.length} catégorie(s) sélectionnée(s)</span>
                    </div>
                    <button type="submit">{editIndex !== null ? 'Mettre à jour' : 'Ajouter'}</button>
                </form>
                <NavLink to="/admin/dashboard">Retour à l'accueil</NavLink>
            </div>
            <div className="vertical-line"></div>
            <div>
                <h2>Entête de la page "Infos"</h2>
                <ul>
                    {infos.map((info, index) => (
                        <li key={info.id}>
                            <div
                                className="adminInfoDiv"
                                onClick={() => handleEdit(index)}
                            >
                                <Image 
                                    classname="imgAdmin"
                                    path={info.picture[0].url}
                                    alt={info.picture[0].altText}
                                    title={info.picture[0].title}
                                />
                                <span>{info.name}</span>
                                <div>{info.description}</div>
                            </div>
                            <button onClick={() => handleDeleteInfo(index)}>Supprimer</button>
                        </li>
                    ))}
                </ul>
            </div>
            {showPicturesModal && (
                <PicturesModal
                    associatedPictures={pictures}
                    allPictures={allPictures}
                    onClose={closePicturesModal}
                    onConfirm={handlePicturesChange}
                />
            )}
            {showCategoriesModal && (
                <CategoriesModal
                    associatedCategories={categories}
                    allCategories={allCategories}
                    onClose={closeCategoriesModal}
                    onConfirm={handleCategoriesChange}
                />
            )}
        </section>
    )
}

export default Infos