import React, { useState, useEffect } from 'react'

import "../../css/pages/admin/Entries.css"
import { NavLink } from 'react-router-dom';

const Entries = () => {

    const [entries, setEntries] = useState([]);
    const [year, setYear] = useState('');
    const [text, setText] = useState('');
    const [url, setUrl] = useState('');
    const [editIndex, setEditIndex] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchEntries = async () => {
            try {
                const apiUrl = process.env.REACT_APP_API_URL;
                const response = await fetch(`${apiUrl}/entries`);
                if (!response.ok) {
                    throw new Error('Erreur lors du chargement des différentes entrées de la page "Infos"');
                }
                const data = await response.json();
                setEntries(data);
                setLoading(false);
            } catch (err) {
                setError(err.message);
                setLoading(false);
            }
        };
        fetchEntries();
    }, []);

    const handleAddEntry = async (newEntry) => {
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const response = await fetch(`${apiUrl}/entries/add`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(newEntry),
            });

            if (!response.ok) {
                throw new Error('Erreur lors de l\'ajout de la nouvelle entrée pour la page "Infos"');
            }

            const createdEntryId = (await response.json()).entryId;
            const createdEntry = { id: createdEntryId, ...newEntry };
            setEntries([...entries, createdEntry]);
        } catch (err) {
            setError(err.message);
        }
    };

    const handleUpdateEntry = async (index, updatedEntry) => {
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const entryId = entries[index].id;
            const response = await fetch(`${apiUrl}/entries/${entryId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(updatedEntry),
            });

            if (!response.ok) {
                throw new Error('Erreur lors de la mise à jour de l\'entrée de la page "Infos"');
            }

            const updatedEntries = [...entries];
            updatedEntries[index] = { ...entries[index], ...updatedEntry };
            setEntries(updatedEntries);
            setEditIndex(null);
        } catch (err) {
            setError(err.message);
        }
    };

    const handleDeleteEntry = async (index) => {
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const entryId = entries[index].id;
            const response = await fetch(`${apiUrl}/entries/${entryId}/delete`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Erreur lors de la suppression de l\'ancienne entrée de la page "Infos"');
            }

            const updatedEntries = entries.filter((_, i) => i !== index);
            setEntries(updatedEntries);
        } catch (err) {
            setError(err.message);
        }
    };

    const refreshEntries = async () => {
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const response = await fetch(`${apiUrl}/entries`);
            if (!response.ok) throw new Error('Erreur lors du rechargement des entrées de la page "Infos"');
            const data = await response.json();
            setEntries(data);
        } catch (err) {
            console.error('Erreur lors du rechargement de l\'entrée de la page "Infos" :', err);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const newEntry = {
            year,
            text,
            url
        };

        if (editIndex !== null) {
            await handleUpdateEntry(editIndex, newEntry);
        } else {
            await handleAddEntry(newEntry);
        }

        await refreshEntries();

        setYear('');
        setText('');
        setUrl('');
        setEditIndex(null);
    };

    const handleEdit = (index) => {
        const entry = entries[index];
        setYear(entry.year || '');
        setText(entry.text || '');
        setUrl(entry.url || '');
        setEditIndex(index);
    };

    if (loading) {
        return <div>Chargement des entrées de la page "Infos"...</div>;
    }

    if (error) {
        return <div>Erreur : {error}</div>;
    }
    
    return (
        <section className="admin_entries">
            <div>
                <h1>Gestion des entrées de la page "Infos"</h1>
                <form onSubmit={handleSubmit}>
                    <div>
                        <label htmlFor="year">Année: </label>
                        <input
                            type="text"
                            id="year"
                            value={year || ''}
                            onChange={(e) => setYear(e.target.value)}
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="text">Texte: </label>
                        <input
                            type="text"
                            id="text"
                            value={text || ''}
                            onChange={(e) => setText(e.target.value)}
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="url">Url: </label>
                        <input
                            type="text"
                            id="url"
                            value={url || ''}
                            onChange={(e) => setUrl(e.target.value)}
                        />
                    </div>
                    <button type="submit">{editIndex !== null ? 'Mettre à jour' : 'Ajouter'}</button>
                </form>
                <NavLink to="/admin/dashboard">Retour à l'accueil</NavLink>
            </div>
            <div className="vertical-line"></div>
            <div>
                <h2>Entrées de la page "Infos"</h2>
                <ul>
                    {entries.map((entry, index) => (
                        <li key={entry.id}>
                            <div
                                className="adminEntryDiv"
                                onClick={() => handleEdit(index)}
                            >
                                <div>{entry.year} - {entry.text}</div>
                                <span>{entry.url}</span>
                            </div>
                            <button onClick={() => handleDeleteEntry(index)}>Supprimer</button>
                        </li>
                    ))}
                </ul>
            </div>
        </section>
    )
}

export default Entries