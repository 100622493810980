import React, { useEffect, useState } from 'react';

import Header from '../components/Header';
import Paragraph from '../components/HTMLComponents/Paragraph';
import Image from '../components/HTMLComponents/Image';
import { ShopHeader } from '../components/HeaderComponents/ShopHeader';

import '../css/pages/Projects.css';

const Projects = () => {
    const [projects, setProjects] = useState([]); 
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null); 
    const [selectedFilters, setSelectedFilters] = useState({
        checkboxes: [], // Gère les cases à cocher
    });

    let classname = 'projects';
    if (window.location.pathname === '/projets') classname += ' fixed_main';

    useEffect(() => {
        const fetchProjectsAndPictures = async () => {
            try {
                const apiUrl = process.env.REACT_APP_API_URL;
                const response = await fetch(`${apiUrl}/projects/pictures`);
                if (!response.ok) throw new Error('Erreur lors du chargement des projets');
                const projectsData = await response.json();
                setProjects(projectsData);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchProjectsAndPictures();
    }, []);

    if (loading) return <div>Chargement des projets...</div>;
    if (error) return <div>Erreur : {error}</div>;
    if (projects.length === 0) return <div>Aucun projet disponible.</div>;

    const handleFilterChange = (dataValue, isChecked) => {
        setSelectedFilters((prev) => ({
            ...prev,
            checkboxes: isChecked
                ? [...prev.checkboxes, dataValue]
                : prev.checkboxes.filter((value) => value !== dataValue),
        }));
    };
    

    const filteredProjects =
    selectedFilters.checkboxes.length === 0
        ? projects
        : projects.filter((project) => {
            return (
                selectedFilters.checkboxes.includes(project.title) || 
                selectedFilters.checkboxes.includes(project.year)
            );
        });

    return (
        <>
            <Header 
                nameFilter={"projects"} 
                filters={projects} 
                onFilterChange={handleFilterChange} 
                selectedFilters={selectedFilters}
            />
            <main className={classname}>
                {filteredProjects.map((project) => (
                    <div key={project.id} className="project_section">
                        <section className="projects_header">
                            <h1>{project.title}</h1>
                            <span>{project.year}</span>
                        </section>
                        <Paragraph content={[{ id: 1, text: project.description }]} />
                        <section className="projects_grid">
                            {project.pictures.map((picture) => (
                                <Image
                                    key={picture.id}
                                    path={picture.url}
                                    isFigcaption={!!picture.figcaption}
                                    title={picture.figcaption || ''}
                                />
                            ))}
                        </section>
                    </div>
                ))}
            </main>
            <ShopHeader />
        </>
    );
};

export default Projects;
