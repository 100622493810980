import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'

import ProtectedRoute from './ProtectedRoute';

import Home from './pages/Home';
import Projects from './pages/Projects';
import Activities from './pages/Activities';
import { Shop } from './pages/Shop';
import { Product } from './pages/Product';
import Cart from './pages/Cart';
import Infos from './pages/Infos';
import { Credits } from './pages/Credits';

import Auth from './pages/admin/Auth';
import Dashboard from './pages/admin/Dashboard';
import Users from './pages/admin/Users';
import Pictures from './pages/admin/Pictures';
import Keywords from './pages/admin/Keywords';
import AdminProjects from './pages/admin/Projects';
import AdminActivities from './pages/admin/Activities';
import Collections from './pages/admin/Collections';
import Series from './pages/admin/Series';
import Formats from './pages/admin/Formats';
import Products from './pages/admin/Products';
import Payments from './pages/admin/Payments';
import Shipments from './pages/admin/Shipments';
import AdminInfos from './pages/admin/Infos';
import Categories from './pages/admin/Categories';
import Entries from './pages/admin/Entries';
import RecycleBin from './pages/admin/RecycleBin';

import './css/App.css';

function App() {
  return (
    <div className="App">
      <React.Fragment>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/projets" element={<Projects />} />
            <Route path="/activites" element={<Activities />} />
            <Route path="/boutique" element={<Shop />} />
            <Route path="/produit/:id" element={<Product />} />
            <Route path="/panier" element={<Cart />} />
            <Route path="/infos" element={<Infos />} />
            <Route path="/credits" element={<Credits />} />
            <Route path="/admin" element={<Auth />} />
            <Route path="/admin/dashboard" element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            } />
            <Route path="/admin/users" element={
              <ProtectedRoute>
               <Users />
              </ProtectedRoute>
            } />
            <Route path="/admin/keywords" element={
              <ProtectedRoute>
               <Keywords />
              </ProtectedRoute>
            } />
            <Route path="/admin/pictures" element={
              <ProtectedRoute>
               <Pictures />
              </ProtectedRoute>
            } />
            <Route path="/admin/projects" element={
              <ProtectedRoute>
               <AdminProjects />
              </ProtectedRoute>
            } />
            <Route path="/admin/activities" element={
              <ProtectedRoute>
               <AdminActivities />
              </ProtectedRoute>
            } />
            <Route path="/admin/collections" element={
              <ProtectedRoute>
               <Collections />
              </ProtectedRoute>
            } />
            <Route path="/admin/series" element={
              <ProtectedRoute>
               <Series />
              </ProtectedRoute>
            } />
            <Route path="/admin/formats" element={
              <ProtectedRoute>
               <Formats />
              </ProtectedRoute>
            } />
            <Route path="/admin/products" element={
              <ProtectedRoute>
               <Products />
              </ProtectedRoute>
            } />
            <Route path="/admin/payments" element={
              <ProtectedRoute>
               <Payments />
              </ProtectedRoute>
            } />
            <Route path="/admin/shipments" element={
              <ProtectedRoute>
               <Shipments />
              </ProtectedRoute>
            } />
            <Route path="/admin/infos" element={
              <ProtectedRoute>
               <AdminInfos />
              </ProtectedRoute>
            } />
            <Route path="/admin/categories" element={
              <ProtectedRoute>
               <Categories />
              </ProtectedRoute>
            } />
            <Route path="/admin/entries" element={
              <ProtectedRoute>
               <Entries />
              </ProtectedRoute>
            } />
            <Route path="/admin/recyclebin" element={
              <ProtectedRoute>
               <RecycleBin />
              </ProtectedRoute>
            } />
          </Routes>
        </BrowserRouter>
      </React.Fragment>
    </div>
  );
}

export default App;
