import React from 'react';
import FilterChecklist from './FilterComponents/FilterChecklist';
import { filterConfig } from '../../filterConfig';

import '../../css/components/HeaderComponents/FilterHeader.css';

export const FilterHeader = ({ nameFilter, filters, onFilterChange, selectedFilters }) => {
  const config = filterConfig[nameFilter];

  if (!config) {
    return null;
  }

  const allPrices = filters.flatMap(filter =>
    filter.series?.flatMap(serie =>
      serie.products?.map(product => product.price_ttc) || []
    ) || []
  );
  const maxPrice = allPrices.length > 0 ? Math.max(...allPrices) : 1000;

  const activeCheckboxFilters = selectedFilters?.checkboxes || [];
  const activeMaxPrice = selectedFilters?.maxPrice;

  return (
    <div className="filter_header">
      <div className="menu_main">
        <h1>FILTRES</h1>
        {(activeCheckboxFilters.length > 0 || activeMaxPrice) && (
          <div className="active_filters">
            <ul>
              {config.map(({ label, field }) => {
                // Obtenir les valeurs actives et les dédupliquer
                const activeForFilter = Array.from(
                  new Set(
                    filters.flatMap(filter => {
                      if (field === 'format') {
                        return filter.series.flatMap(serie =>
                          serie.products.flatMap(product => product.formats.map(f => f.name))
                        );
                      } else if (field === 'product') {
                        return filter.series.flatMap(serie => serie.products.map(product => product.name));
                      } else if (field === 'serie') {
                        return filter.series.map(serie => serie.title);
                      } else if (field === 'collection') {
                        return [filter.name];
                      } else if (field === 'title') {
                        return [filter.title];
                      } else if (field === 'year') {
                        return [filter.year];
                      } else if (field === 'name') {
                        return filter.categories.map(category => category.name);
                      } else if (field === 'entryYear') {
                        return filter.categories.flatMap(category => category.entries.map(entry => entry.year));
                      }
                      return [];
                    })
                  )
                ).filter(item => activeCheckboxFilters.includes(item)); // Filtrer les valeurs actives

                if (activeForFilter.length === 0) return null;

                return (
                  <li key={field}>
                    <h2>{label}</h2>
                    <div className="active_filter_values">
                      {activeForFilter.map((activeItem, index) => (
                        <span
                          key={`${field}-${index}`}
                          className="active_filter_value"
                          onClick={() => onFilterChange(activeItem, false)}
                          title="Cliquez pour retirer"
                        >
                          {activeItem}
                          <span className="remove_cross">×</span>
                        </span>
                      ))}
                    </div>
                  </li>
                );
              })}
              {activeMaxPrice && (
                <li className="price-active-value">
                  <h2>Prix</h2>
                  <div className="active_filter_values">
                    <span className="active_filter_value">
                      Prix maximum : {activeMaxPrice} €
                    </span>
                  </div>
                </li>
              )}
            </ul>
          </div>
        )}
      </div>

      <div className="menu_expandable">
        {config.map(({ label, field }) => {
          let datas = [];
          if (field === 'price') {
            datas = [];
          } else {
            datas = filters.flatMap(filter => {
              if (field === 'format') {
                return filter.series.flatMap(serie =>
                  serie.products.flatMap(product => product.formats.map(f => f.name))
                );
              } else if (field === 'product') {
                return filter.series.flatMap(serie => serie.products.map(product => product.name));
              } else if (field === 'serie') {
                return filter.series.map(serie => serie.title);
              } else if (field === 'collection') {
                return filter.name;
              } else if (field === 'title') {
                return filter.title;
              } else if (field === 'year') {
                return filter.year;
              } else if (field === 'name') {
                return filter.categories.map(category => category.name);
              } else if (field === 'entryYear') {
                return filter.categories.flatMap(category => category.entries.map(entry => entry.year));
              }
              return [];
            });
          }
          datas = [...new Set(datas)]; // Dédupliquer les données

          return (
            <FilterChecklist
              key={field}
              name={label}
              datas={datas}
              maxPrice={field === 'price' ? maxPrice : undefined}
              selectedFilters={selectedFilters}
              onChange={onFilterChange}
            />
          );
        })}
      </div>
    </div>
  );
};
